<template>
    <div class="Detail">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    日志详情
                </div>
            </div>
            <el-divider></el-divider>
            <div class="formbox">
                <el-row>
                    <el-col :span="6">
                        <div class="info">
                            <label>日志编号：</label>{{ logInfo.id }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info">
                            <label>模块：</label>{{ logInfo.modelName }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info">
                            <label>模块描述：</label>{{ logInfo.option }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info">
                            <label>操作账号：</label>{{ logInfo.operName }}
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <div class="info">
                            <label>ip地址：</label>{{ logInfo.operIp }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="info">
                            <label>请求接口：</label>{{ logInfo.operUrl }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info">
                            <label>请求方式：</label>{{ logInfo.requestMethod }}
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="16">
                        <div class="info">
                            <label>请求的类方法：</label>{{ logInfo.method }}
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="info">
                            <label>请求时间：</label>{{ logInfo.operTime }}
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="info">
                            <label>请求参数：</label>
                            <el-input type="textarea" :maxlength="100" :autosize="{ minRows: 1, maxRows: 5 }"
                            readonly v-model="logInfo.operParam">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="info">
                            <label>响应信息：</label>
                            <el-input type="textarea" :maxlength="100" :autosize="{ minRows: 1, maxRows: 5 }"
                                 readonly v-model="logInfo.jsonResult">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="info">
                            <label> 错误信息：</label>
                            <el-input type="textarea" :maxlength="100" :autosize="{ minRows: 1, maxRows: 5 }"
                            readonly v-model="logInfo.errorMsg">
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>
<script>
import { getOperLog } from '@/api/log'
export default {
    name: 'detailIndex',
    props: {
        id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            logInfo: {
                id: '',
                modelName: '',
                option: '',
                operName: '',
                operIp: '',
                operUrl: '',
                requestMethod: '',
                method: '',
                operTime: '',
                operParam: '',
                jsonResult: '',
                errorMsg: ''
            }
        }
    },
    mounted() {
        this.loadLogInfo()
    },
    methods: {
        //查询
        loadLogInfo() {
            getOperLog(this.id).then(res => {
                if (res.code === '000000') {
                    this.logInfo = res.data
                }

            })

        },
    }
}
</script>
<style lang="scss" scoped>
.Detail {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .el-row {
            margin-bottom: 15px;

            .el-col {
                .info {
                    text-align: left;
                    font-size: 14px;
                    color: #4e5969;

                    label {
                        display: inline-block;
                        margin-right: 10px;
                        margin-bottom: 10px;

                    }

                }
            }
        }
    }



}
</style>
  